<template>
	<a-space direction="vertical">

		<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 8 }, wrapperCol: { span: 14 } }">
			<a-row>
				<a-col :md="6" :sm="24">
					<a-form-model-item label="区县地址">
						<a-cascader :options="countyData" :fieldNames="{label:'name',value:'name',children:'children'}" :value="selectedRegion" placeholder="请选择区县地址" changeOnSelect @change="([ province, city, county]) => Object.assign(this.query, { province, city, county})" />
					</a-form-model-item>
				</a-col>
				<a-col :md="6" :sm="24">
					<a-form-model-item label="手机号/昵称"><a-input v-model="query.mobile" placeholder="请输入手机号/昵称"></a-input></a-form-model-item>
				</a-col>
				<a-col :md="6" :sm="24">
					<a-form-model-item label="类型">
						<a-select style="width: 100%" v-model="query.type" placeholder="请选择类型" :allow-clear="true">
							<a-select-option :value="1">
								白番茄
							</a-select-option>
							<a-select-option :value="5">
								艾发美
							</a-select-option>
						</a-select>
					</a-form-model-item> </a-col>
				<a-col :md="6" :sm="24">
					<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
						<a-space>
							<a-button type="primary" html-type="submit">查询</a-button>
							<a-button @click="onReset">重置</a-button>
						</a-space>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<div class="statistics">
			<span>用户总数：{{ lists.total }}</span>
		</div>
		<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
			<a-table-column key="mobile" title="用户" data-index="mobile">
				<template slot-scope="text, record">
					{{ record.userId }}
					<br />
					{{ record.nickname }} ({{ record.mobile }})
				</template>
			</a-table-column>
			<a-table-column title="区代地址">
				<template slot-scope="text, record">
					{{ record.province }}{{ record.city }}{{ record.county }}
				</template>
			</a-table-column>
			<a-table-column title="类型" align="center">
				<template slot-scope="text, record">
					{{ record.typeStr || '--' }}
				</template>
			</a-table-column>
			<a-table-column key="stateStr" title="状态" data-index="stateStr" align="center" />
			<a-table-column key="ctime" title="创建时间" data-index="ctime" />
			<a-table-column key="action" title="操作" :width="140">
				<template slot-scope="text, record">
					<a-space>
						<a-popconfirm title="确认要删除吗？" @confirm="onDelete(record)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</a-space>
				</template>
			</a-table-column>
		</a-table>

		<a-modal v-drag-modal :width="600" title="新增代理" v-model="modalAgent.visible">
			<div slot="footer">
				<a-space>
					<a-button @click="modalAgent.visible = false">取消</a-button>
					<a-button type="primary" @click="onSaveAgent">确定</a-button>
				</a-space>
			</div>

			<a-form-model ref="form" :model="modalAgent.formdata" :rules="modalAgent.rules" v-bind="layout">
				<a-form-model-item label="用户手机号" prop="mobile"><a-input v-model="modalAgent.formdata.mobile" placeholder="请输入用户手机号" /></a-form-model-item>
				<a-form-model-item label="区县地址" prop="county">
					<a-cascader :options="countyData" :fieldNames="{label:'name',value:'name',children:'children'}" :value="modalAgent.formdata.county ? [modalAgent.formdata.province,modalAgent.formdata.city,modalAgent.formdata.county] : [] " placeholder="请选择区县地址" @change="([ province, city, county]) => Object.assign(modalAgent.formdata, { province, city, county})" />
				</a-form-model-item>
				<a-form-model-item label="保证金" prop="payment">
					<a-row type="flex" :gutter="12">
						<a-col flex="1">
							<a-input v-model="modalAgent.formdata.payment" placeholder="请输入保证金" :disabled="!modalAgent.bond.userId" />
						</a-col>
						<a-col>
							<a-button @click="onCheckPayment">查询</a-button>
						</a-col>
					</a-row>
					<p slot="help" v-if="modalAgent.bond.userId">
						当前用户有保证金 {{ modalAgent.bond.userBond }}元 还需补交 {{ modalAgent.bond.payment }}元
					</p>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import VueViewer from 'vue-viewerjs';
	const formatter = 'YYYY-MM-DD';
	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		mobile: undefined,
		province: undefined,
		city: undefined,
		county: undefined,
		payment: undefined
	};

	const countyData = require('@/assets/address.json')

	export default {
		components: { VueViewer },
		data() {
			return {
				countyData,
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				},
				modalAgent: {
					visible: false,
					formdata: {
						mobile: undefined,
						province: undefined,
						city: undefined,
						county: undefined,
						payment: undefined
					},
					rules: {
						mobile: { required: true, message: '请输入用户手机号' },
						county: { required: true, message: '请选择区县' },
						payment: { required: true, message: '请输入保证金' },
					},
					bond: {
						userId: '',
						nickname: '',
						userBond: '',
						payment: 0
					}
				},

				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 18
					}
				},
			};
		},
		computed: {
			selectedRegion() {
				const { province, city, county } = this.query;
				const res = []
				province && res.push(province)
				city && res.push(city)
				county && res.push(county)
				return res
			}
		},
		async mounted() {
			this.getAgents();
		},
		methods: {
			async getAgents() {
				const response = await this.$api.get('/agent_user_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					Object.assign(this.lists, response.data);
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
				this.getAgents();
			},
			async onSearch() {
				this.query.page = 1;
				this.getAgents();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getAgents();
			},
			async onChange([province, city, county]) {
				Object.assign(this.query, { province, city, county })
			},
			async onCreate() {
				Object.assign(this.modalAgent.formdata, {
					mobile: undefined,
					province: undefined,
					city: undefined,
					county: undefined,
					payment: undefined
				})
				Object.assign(this.modalAgent.bond, {
					userId: '',
					nickname: '',
					userBond: '',
					payment: 0
				})
				this.modalAgent.visible = true
			},
			async onCheckPayment() {
				const { mobile } = this.modalAgent.formdata
				if (/^1\d{10}$/.test(mobile)) {
					const response = await this.$api.get('/agent_get_bond', {
						params: { mobile }
					});
					if (response && response.code == 200) {
						Object.assign(this.modalAgent.bond, response.data);
					}
					// 18168552845
				}
			},

			async onSaveAgent() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						const response = await this.$api.post('/agent_add', this.modalAgent.formdata);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getAgents();
							this.modalAgent.visible = false;
						} else {
							this.$message.error(response.msg);
						}
					}
				});
			},

			async onDelete(record) {
				const response = await this.$api.get(`/agent_del/${record.id}`);
				if (response && response.code == 200) {
					this.getAgents();
					this.$message.success("操作成功!");
				} else {
					this.$message.error(response.msg);
				}
			},
		}
	};
</script>